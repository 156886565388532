import { useMutation, useQuery } from "@tanstack/react-query";
import { DashboardViewInterface } from "app/screens/Dashboard/interfaces";
import { updateSavedViewsDateRangeAsLabel } from "app/screens/Dashboard/utils";
import { HttpMethod, makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export enum DashboardViewVisibility {
  private = "private",
  public = "public",
}

export interface DashboardViewInput extends DashboardViewCreate {
  id: string;
}

export interface DashboardViewCreate {
  name: string;
  data: DashboardViewInterface;
  dashboard_id: string;
  visibility: DashboardViewVisibility;
}

const fetchDashboardViews = async (
  organisationId: number,
  dashboardId: string
): Promise<Response<{ dashboard_views: DashboardViewInput[] }>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views?organisation_id=${organisationId}&dashboard_id=${dashboardId}`);

const fetchAllDashboardViews = async (
  organisationId: number
): Promise<Response<{ dashboard_views: DashboardViewInput[] }>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views?organisation_id=${organisationId}`);

const deleteDashboardView = async (organisationId: number, dashboardViewId: string): Promise<Response<any>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views/${dashboardViewId}?organisation_id=${organisationId}`, {
    method: HttpMethod.DELETE,
  });

const createDashboardView = async (
  organisationId: number,
  data: DashboardViewCreate
): Promise<Response<{ dashboard_view: DashboardViewInput }>> =>
  await makeQueryRequest(`${HOST}/v1/dashboard_views?organisation_id=${organisationId}`, {
    method: HttpMethod.POST,
    body: data,
  });

const queryKeys = {
  useDashboardViews: (organisationId: number, dashboardId: string) => [
    "fetchDashboardViews",
    organisationId,
    dashboardId,
  ],
  useAllDashboardViews: (organisationId: number) => ["fetchAllDashboardViews", organisationId],
};

const dashboardViews = {
  queryKeys,
  useAllDashboardViews: (organisationId: number | undefined) =>
    useQuery({
      queryKey: queryKeys.useAllDashboardViews(organisationId!),
      queryFn: () => fetchAllDashboardViews(organisationId!),
      enabled: !!organisationId,
      select: (data) => (data.data?.dashboard_views ? updateSavedViewsDateRangeAsLabel(data.data.dashboard_views) : []),
    }),
  useDashboardViews: ({ organisationId, dashboardId }: { organisationId: number | undefined; dashboardId: string }) =>
    useQuery({
      queryKey: queryKeys.useDashboardViews(organisationId!, dashboardId),
      queryFn: () => fetchDashboardViews(organisationId!, dashboardId),
      enabled: !!organisationId,
      select: (data) => (data.data?.dashboard_views ? updateSavedViewsDateRangeAsLabel(data.data.dashboard_views) : []),
    }),
  useDashboardViewDelete: (organisationId: number | undefined, onSuccess: () => void, onError: (error: any) => void) =>
    useMutation({
      mutationFn: (dashboardViewId: string) => deleteDashboardView(organisationId!, dashboardViewId),
      onSuccess: onSuccess,
      onError: onError,
    }),
  useDashboardViewCreate: (
    organisationId: number,
    onSuccess: (data: Response<{ dashboard_view: DashboardViewInput }>) => void,
    onError: (error: any) => void
  ) =>
    useMutation({
      mutationFn: (data: DashboardViewCreate) => createDashboardView(organisationId, data),
      onSuccess: onSuccess,
      onError: onError,
    }),
};

export default dashboardViews;
