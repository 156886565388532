import { primaryPresetRanges as cohortPrimaryPresetRanges } from "app/screens/Dashboard/components/DateRangePicker/components/CohortPicker/utils";
import {
  getComparisonPresetRanges,
  primaryAndComparisonPresetRangeDefaultsMap,
  primaryPresetRanges,
} from "app/screens/Dashboard/components/DateRangePicker/components/DateRangeComparisonPicker/utils";
import {
  AnalyticsComponentFilterValueMap,
  DashboardInterface,
  DashboardViewComponentType,
  DashboardViewInterface,
  DatePickerEnum,
  DimFilter,
  PrimaryPresetRangeLabel,
  ValueFilter,
} from "app/screens/Dashboard/interfaces";
import { DashboardViewInput, DashboardViewVisibility } from "app/services/APIService/dashboardViews";
import {
  createComparisonDateRangesKey,
  createComponentKey,
  createDimFilterKey,
  createPrimaryDateRangeKey,
  createValueFilterKey,
  getComponentStateFromJson,
  getDateRangeFromJson,
  JsonDateRangeKeyType,
} from "app/services/UrlQueryStateService/dashboard";
import { DateRangeType } from "app/utils/dateutils";
import queryString from "query-string";

import { getDefaultSelectedFilters } from "./utils";

export const hasDashboardStateInUrl = (dashboardId: string, search: string = location.search) => {
  const currentUrlSearchParams = queryString.parse(search);
  const searchKeys = Object.keys(currentUrlSearchParams);
  return searchKeys.some((key) => key.includes(`${dashboardId}-`));
};

export const getDashboardViewState = (
  dashboardMetadata: DashboardInterface,
  search = location.search
): DashboardViewInterface => {
  /**
   * getDashboardViewState takes in a dashboardMetadata object, and reads the url query params to construct a saved view object
   * it picks relevant url query params for the dashboard and make a saved view object with DashboardViewInterface type.
   */
  const currentUrlSearchParams = queryString.parse(search);
  const dashboardViewComponents: DashboardViewComponentType[] = [];
  const dashboardViewDimFilters: DimFilter[] = [];
  const dashboardViewValueFilters: ValueFilter[] = [];
  const dashboardViewDateRangePicker: DashboardViewInterface["date_range_picker"] = {
    type: dashboardMetadata.header_items.date_range_picker.type,
    display: dashboardMetadata.header_items.date_range_picker.display,
    primary_date_range: {},
    comparison_date_ranges: {},
  };

  const dimFilterKey = createDimFilterKey(dashboardMetadata.id);
  if (currentUrlSearchParams[dimFilterKey]) {
    const savedDimFilterState = JSON.parse(currentUrlSearchParams[dimFilterKey] as string) as DimFilter[];
    dashboardViewDimFilters.push(...savedDimFilterState);
  }

  const valueFilterKey = createValueFilterKey(dashboardMetadata.id);
  if (currentUrlSearchParams[valueFilterKey]) {
    const savedValueFilterState = JSON.parse(currentUrlSearchParams[valueFilterKey] as string) as ValueFilter[];
    dashboardViewValueFilters.push(...savedValueFilterState);
  }

  const primaryDateRangeKey = createPrimaryDateRangeKey(dashboardMetadata.header_items.date_range_picker.type);

  if (currentUrlSearchParams[primaryDateRangeKey]) {
    dashboardViewDateRangePicker.primary_date_range = JSON.parse(
      currentUrlSearchParams[primaryDateRangeKey] as string
    ) as JsonDateRangeKeyType;
  }

  const comparisonDateRangesKey = createComparisonDateRangesKey(dashboardMetadata.header_items.date_range_picker.type);
  if (currentUrlSearchParams[comparisonDateRangesKey]) {
    dashboardViewDateRangePicker.comparison_date_ranges = JSON.parse(
      currentUrlSearchParams[comparisonDateRangesKey] as string
    ) as Record<string, JsonDateRangeKeyType>;
  }

  dashboardMetadata.components.forEach((component, index) => {
    const componentKey = createComponentKey(dashboardMetadata.id, component.type, index);
    const dashboardViewComponent: DashboardViewComponentType = {
      type: component.type,
      filters: component.filters.map((componentFilter) => ({
        type: componentFilter.type,
        default_value: componentFilter.default_value,
      })),
    };

    if (currentUrlSearchParams[componentKey]) {
      const savedState = JSON.parse(currentUrlSearchParams[componentKey] as string) as AnalyticsComponentFilterValueMap;
      const defaultState = getDefaultSelectedFilters(dashboardViewComponent.filters);
      const viewState = getComponentStateFromJson(savedState, defaultState);

      dashboardViewComponent.filters = dashboardViewComponent.filters.map((filter) => {
        return {
          type: filter.type,
          default_value: viewState[filter.type],
        };
      });
    }
    dashboardViewComponents.push(dashboardViewComponent);
  });

  return {
    id: dashboardMetadata.id,
    // If semver version is not mentioned in dashboard metadata, it will by default use 0.0.1
    version: dashboardMetadata.version ?? "0.0.1",
    dim_filters: dashboardViewDimFilters,
    value_filters: dashboardViewValueFilters,
    comparisons: dashboardMetadata.comparisons,
    components: dashboardViewComponents,
    date_range_picker: dashboardViewDateRangePicker,
  };
};

export const getDashboardViewUrlState = (data: DashboardViewInterface): string => {
  const urlSearchParams: Record<string, string> = {};
  if (data.dim_filters.length > 0) {
    const dimFilterKey = createDimFilterKey(data.id);
    urlSearchParams[dimFilterKey] = JSON.stringify(data.dim_filters);
  }
  if (data.value_filters.length > 0) {
    const valueFilterKey = createValueFilterKey(data.id);
    urlSearchParams[valueFilterKey] = JSON.stringify(data.value_filters);
  }
  if (Object.keys(data.date_range_picker.primary_date_range).length > 0) {
    const primaryDateRangeKey = createPrimaryDateRangeKey(data.date_range_picker.type);
    urlSearchParams[primaryDateRangeKey] = JSON.stringify(data.date_range_picker.primary_date_range);
  }

  if (Object.keys(data.date_range_picker.comparison_date_ranges).length > 0) {
    const comparisonDateRangesKey = createComparisonDateRangesKey(data.date_range_picker.type);
    urlSearchParams[comparisonDateRangesKey] = JSON.stringify(data.date_range_picker.comparison_date_ranges);
  }

  data.components.forEach((component, index) => {
    const componentKey = createComponentKey(data.id, component.type, index);
    urlSearchParams[componentKey] = JSON.stringify(getDefaultSelectedFilters(component.filters));
  });
  return queryString.stringify(urlSearchParams);
};

export const getDefaultDashboardView = (dashboardMetadata: DashboardInterface): DashboardViewInput => {
  return {
    id: "",
    name: "Default",
    data: getDashboardViewState(dashboardMetadata, ""),
    dashboard_id: dashboardMetadata.id,
    visibility: DashboardViewVisibility.private,
  };
};

export const updateSavedViewsDateRangeAsLabel = (savedViews: DashboardViewInput[]) =>
  savedViews.map((savedView) => {
    const dateRangePicker = savedView.data.date_range_picker;
    const primaryDateRange = dateRangePicker.primary_date_range as JsonDateRangeKeyType;
    const comparisonDateRanges = dateRangePicker.comparison_date_ranges as Record<string, JsonDateRangeKeyType>;

    const updateRange = (previousDateRange: JsonDateRangeKeyType, newDateRange: DateRangeType) => ({
      ...previousDateRange,
      startDate: newDateRange.startDate.toISOString(),
      endDate: newDateRange.endDate.toISOString(),
    });

    if (primaryDateRange && dateRangePicker.type === DatePickerEnum.DEFAULT) {
      const range = primaryPresetRanges().find((primaryPreset) => primaryPreset.label === primaryDateRange.label);

      if (range) {
        savedView.data.date_range_picker.primary_date_range = updateRange(primaryDateRange, range.range());
      }
    }

    if (primaryDateRange && dateRangePicker.type === DatePickerEnum.COHORTS) {
      const range = cohortPrimaryPresetRanges().find((primaryPreset) => primaryPreset.label === primaryDateRange.label);

      if (range) {
        savedView.data.date_range_picker.primary_date_range = updateRange(primaryDateRange, range.range());
      }
    }

    if (primaryDateRange && comparisonDateRanges) {
      Object.keys(comparisonDateRanges).forEach((comparisonDateRange) => {
        const range = getComparisonPresetRanges(getDateRangeFromJson(primaryDateRange)).find(
          (comparisonPresetRange) =>
            comparisonPresetRange.label ===
            primaryAndComparisonPresetRangeDefaultsMap[primaryDateRange.label as PrimaryPresetRangeLabel]
        );

        if (range) {
          comparisonDateRanges[comparisonDateRange] = updateRange(
            comparisonDateRanges[comparisonDateRange],
            range.range()
          );
        }
      });
    }

    return savedView;
  });
