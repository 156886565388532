import { connectorCategoryLabels } from "app/components/auth/UserOnboarding/hooks/useConnectorsCategoryGroup";
import {
  AnalyticsComponentFilterValueMap,
  DashboardAvailability,
  DashboardComparisons,
  DashboardComparisonsEnum,
  DimensionInterface,
  FilterType,
  FilterTypeMap,
  Granularity,
  MetricInterface,
  ServerDateRangeType,
  ValueFilter,
} from "app/screens/Dashboard/interfaces";
import { ConnectorCategory } from "app/services/APIService/connectorsV2";
import {
  DateRangeKeyType,
  firstDateOfNextMonth,
  firstDateOfNextYear,
  getTimezoneOffsetInMs,
  nextDate,
} from "app/utils/dateutils";
import { addDays, differenceInDays, nextMonday } from "date-fns";
import moment from "moment";

export const getDateRangeFromServerDateRange = (dateRange: ServerDateRangeType): DateRangeKeyType => {
  /*
    This function also replaces the server's timezone with client's timezone without changing the date or time.
    Example, if client's timezone is UTC+5:30:
    2022-07-25T00:00:00+00:00 or 2022-07-25T00:00:00Z
    will be converted to
    2022-07-25T00:00:00+05:30
  */
  const start_date = new Date(dateRange.start_date);
  const end_date = new Date(dateRange.end_date);

  const startDate = new Date(start_date.getTime() + getTimezoneOffsetInMs(start_date));
  const endDate = new Date(end_date.getTime() + getTimezoneOffsetInMs(end_date));

  return {
    startDate,
    endDate,
    key: dateRange.key,
    label: "",
  };
};

export const getServerDateRangeFromDateRange = (dateRange: DateRangeKeyType): ServerDateRangeType => {
  /*
    This function also replaces the client's timezone with utc without changing the date or time.
    Example, if client's timezone is UTC+5:30:
    2022-07-25T00:00:00+05:30
    will be converted to
    2022-07-25T00:00:00Z
  */

  const { startDate, endDate } = dateRange;

  return {
    start_date: new Date(startDate.getTime() - getTimezoneOffsetInMs(startDate)).toISOString(),
    end_date: new Date(endDate.getTime() - getTimezoneOffsetInMs(endDate)).toISOString(),
    key: dateRange.key,
  };
};

export const getDatesListFromRangeAndGranularity = ({
  dateRange,
  granularity,
  relativeDateRange,
}: {
  dateRange: DateRangeKeyType;
  granularity: Granularity;
  relativeDateRange?: DateRangeKeyType;
}): Date[] => {
  /*
   * This function takes in a date range and granularity, and returns
   * all the dates between the range, with difference between the dates
   * decided by the value of {granularity}.
   *
   * relativeDateRange is used to add additional dates in the date list when relative date range
   * is greater than the selected date range
   * */
  const addGranularityToDateFunctionMap: Record<Granularity, (date: Date) => Date> = {
    none: (date: Date) => date,
    day: nextDate,
    week: nextMonday,
    month: firstDateOfNextMonth,
    year: firstDateOfNextYear,
  };
  const { startDate, endDate } = dateRange;
  let currentDate = new Date(startDate);
  const dates = [];
  let extraDates = 0;
  const dateRangeDays = differenceInDays(dateRange.endDate, dateRange.startDate);

  if (relativeDateRange) {
    const relativeDateDays = differenceInDays(relativeDateRange.endDate, relativeDateRange.startDate);
    if (dateRangeDays < relativeDateDays) {
      extraDates = relativeDateDays - dateRangeDays;
    }
  }

  while (addDays(endDate, extraDates).getTime() - currentDate.getTime() >= 0) {
    dates.push(currentDate);
    const addGranularity = addGranularityToDateFunctionMap[granularity];
    currentDate = addGranularity(currentDate);
  }
  return dates;
};

const granularityDateFormatMap: Record<Granularity, string> = {
  none: "DD MMM YYYY",
  day: "DD MMM YYYY",
  week: "DD MMM YYYY",
  month: "MMM YYYY",
  year: "YYYY",
};

export const formatDateForGranularity = ({ date, granularity }: { date: Date; granularity: Granularity }) => {
  return moment(date).format(granularityDateFormatMap[granularity]);
};

export const getSafeSplitName = (split: string) => {
  return split.toLowerCase().replace(/ /g, "_");
};

export const getDimensionsMetadataMap = (
  dimensions: DimensionInterface[]
): Record<DimensionInterface["id"], DimensionInterface> => {
  const dimensionsMetadataMap: Record<DimensionInterface["id"], DimensionInterface> = {};
  dimensions.forEach((dimension) => {
    dimensionsMetadataMap[dimension.id] = dimension;
  });
  return dimensionsMetadataMap;
};

export const getMetricsMetadataMap = (metrics: MetricInterface[]): Record<MetricInterface["id"], MetricInterface> => {
  const metricMetadataMap: Record<MetricInterface["id"], MetricInterface> = {};
  metrics.forEach((metric) => {
    metricMetadataMap[metric.id] = metric;
  });
  return metricMetadataMap;
};

export const getDefaultSelectedFilters = (
  filters: (FilterType | Pick<FilterType, "type" | "default_value">)[]
): AnalyticsComponentFilterValueMap => {
  const defaultSelectedFilters: Partial<AnalyticsComponentFilterValueMap> = {};
  filters.forEach((filter) => {
    defaultSelectedFilters[filter.type] = filter.default_value as any;
  });
  return defaultSelectedFilters as AnalyticsComponentFilterValueMap;
};

export const getComponentFiltersMap = (filters: FilterType[]): FilterTypeMap => {
  const filtersMap: Record<string, FilterType> = {};
  filters.forEach((filter) => {
    filtersMap[filter.type] = filter;
  });
  return filtersMap as FilterTypeMap;
};

interface CustomOption {
  label: string;
  value: string;
}

export const NoneOption: Readonly<CustomOption> = {
  label: "None",
  value: "none",
};

const metricAvailabilityOverrides: Record<string, Record<"dependantSources", ConnectorCategory[][]>> = {
  // We want to make a few metrics available even if certain sources are missing. These overrides help us achieve that.
  contribution: {
    dependantSources: [[ConnectorCategory.ECOMMERCE], [ConnectorCategory.ERP]],
  },
  contribution_margin: {
    dependantSources: [[ConnectorCategory.ECOMMERCE], [ConnectorCategory.ERP]],
  },
};

type SourceCondition = ConnectorCategory[] | ConnectorCategory[][];

const getMissingSourcesForTables = (
  sources: SourceCondition,
  connectedSources: Record<ConnectorCategory, string[]>
): ConnectorCategory[][] => {
  if (sources.length === 0) {
    return [];
  }

  // Handle flat array (legacy format) - AND relation
  // Later this can be deleted when there won't be availability files with the old format.
  if (typeof sources[0] === "string") {
    const missingSources = (sources as ConnectorCategory[]).filter((source) => connectedSources[source]?.length === 0);
    if (missingSources.length === 0) {
      return [];
    }
    return [missingSources];
  }

  // Handle nested array - OR between groups, AND within groups
  const groupsWithMissingSources = (sources as ConnectorCategory[][]).map((group) =>
    group.filter((source) => connectedSources[source]?.length === 0)
  );
  // If there is one empty group at least, that means that one source condition is met for the table,
  // so we don't need to care about the other groups' missing sources.
  if (groupsWithMissingSources.some((group) => group.length === 0)) {
    return [];
  }
  return groupsWithMissingSources;
};

interface RecordOfStringArrays {
  [key: string]: ConnectorCategory[][];
}

function uniqueAndFilterSupersetsAcrossKeys(record: RecordOfStringArrays): RecordOfStringArrays {
  // This function handles dependencies, de-duplicates them across tables, also deletes
  // those dependencies that are part of other, stricter dependencies (those that have more elements).
  // 1) Flatten everything into a single array while remembering the original key
  const entries: { arr: string[]; key: string }[] = [];
  for (const [k, listOfArrays] of Object.entries(record)) {
    for (const subArr of listOfArrays) {
      entries.push({ arr: subArr, key: k });
    }
  }

  // 2) Normalize & deduplicate (ignoring order)
  //
  //    We'll keep a Map from the sorted-join key => { arr, keyOfFirstOccurrence }
  //    so if we see the same elements again (even under a different key),
  //    we skip them.
  const seenMap = new Map<string, { arr: string[]; key: string }>();
  for (const { arr, key } of entries) {
    const sorted = [...arr].sort();
    const joinKey = sorted.join("|");
    if (!seenMap.has(joinKey)) {
      seenMap.set(joinKey, { arr: sorted, key });
    }
  }

  // At this point, we have unique sub-arrays ignoring order, each mapped to
  // the first key in which it appeared.

  // 3) Filter out any array that is a strict subset of a strictly larger array
  //    We'll do this across all sub-arrays combined, i.e. we treat them like one big set.
  const uniqueArrays = Array.from(seenMap.values()).map((v) => v.arr);
  const filtered = uniqueArrays.filter((candidate) => {
    return !uniqueArrays.some((other) => {
      if (other.length <= candidate.length) return false;
      // candidate is a strict subset of other if every item in candidate is in other
      return candidate.every((item) => other.includes(item));
    });
  });

  // 4) Construct the output Record.
  //    Since it "doesn't matter" which key we keep them under if duplicates occur,
  //    we'll just keep them under the key from the first occurrence we stored in seenMap.
  //
  //    We'll create empty arrays for each key. Then we fill them with only
  //    the final arrays that passed the subset filter.
  const result: RecordOfStringArrays = {};
  // Initialize each key with an empty array
  for (const k of Object.keys(record)) {
    result[k] = [];
  }

  // For each array that survived the subset removal, push it to the "first occurrence" key
  for (const arr of filtered) {
    const joinKey = arr.join("|");
    const { key } = seenMap.get(joinKey)!;
    result[key].push(arr as ConnectorCategory[]);
  }

  return result;
}

export const getMissingDataSourcesForMetric = ({
  metric,
  availability,
  metricsMetadataMap,
}: {
  metric: string;
  availability?: DashboardAvailability;
  metricsMetadataMap: Record<MetricInterface["id"], MetricInterface>;
}) => {
  const missingSources: Record<string, ConnectorCategory[][]> = {};
  let normalisedMissingSources: Record<string, ConnectorCategory[][]> = {};
  if (availability) {
    const tableDependencies = metricsMetadataMap[metric].table_dependencies;
    tableDependencies.forEach((tableDependency) => {
      const tableInformationExists = !!availability.data[tableDependency];
      if (tableInformationExists) {
        if (metricAvailabilityOverrides[metric] !== undefined) {
          missingSources[tableDependency] = getMissingSourcesForTables(
            metricAvailabilityOverrides[metric].dependantSources,
            availability.connected_sources
          );
        } else {
          missingSources[tableDependency] = getMissingSourcesForTables(
            availability.data[tableDependency].sources,
            availability.connected_sources
          );
        }
      }
    });

    normalisedMissingSources = uniqueAndFilterSupersetsAcrossKeys(missingSources);
  }

  return { missingSources: normalisedMissingSources };
};

const buildStringFromMissingDataSources = (missingSources: Record<string, ConnectorCategory[][]>) => {
  let result = "";
  result += Object.values(missingSources)
    .map((arrayOfArrays) =>
      arrayOfArrays
        // Modify each string, then join with "and"
        .map((innerArray) => innerArray.map((connector) => connectorCategoryLabels[connector]).join(" and "))
        // Join each group of strings with "or"
        .join(", or ")
    )
    .filter((na) => na.length > 0)
    // Finally join everything with "+"
    .join(" + ");
  return result;
};

export const getErrorMessageFromMissingDataSources = ({
  missingSources,
}: {
  missingSources: Record<string, ConnectorCategory[][]>;
}) => {
  const areThereAnyMissingSources = Object.values(missingSources).some((arr) => arr.length > 0);
  if (areThereAnyMissingSources) {
    const missingSourcesString = buildStringFromMissingDataSources(missingSources);
    const dependencyCount = Object.values(missingSources).flatMap((arr) => arr.flatMap((subArr) => subArr)).length;
    const isMultiple = dependencyCount > 1;
    return `Add ${missingSourcesString} connector${isMultiple ? "s" : ""} to unlock this metric.`;
  }
  return "";
};

export const hasMetricFilter = (dashboardId: string) => {
  const dashboardsThatHaveMetricFilters = ["product-table", "product-table-v2", "order-table", "product-rankings"];

  return dashboardsThatHaveMetricFilters.includes(dashboardId);
};

export const getMetricsFromMetricFilterValues = (valueFilters: ValueFilter[] | undefined) => {
  const metrics: string[] = [];

  !!valueFilters &&
    valueFilters.forEach((filter) => {
      const isMetricExists = metrics.includes(filter.metric);
      if (!isMetricExists) metrics.push(filter.metric);
    });

  return metrics;
};

export const getDashboardComparisons = (
  comparisonDateRanges: Record<string, DateRangeKeyType>
): DashboardComparisons =>
  Object.keys(comparisonDateRanges).length > 0 ? DashboardComparisonsEnum.ENABLED : DashboardComparisonsEnum.DISABLED;
