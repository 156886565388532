import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpMethod, makeQueryRequest, Response } from "common/services/APIService";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

export interface RevenueBandType {
  id: number;
  minimum?: number;
  maximum?: number;
}

export type OrganisationType = {
  id: number;
  name: string;
  domain: string;
  public_id?: string;
  organisation_config: {
    currency: string;
    category?: string;
    subcategory?: string;
    revenue_band?: RevenueBandType;
  };
};

export interface OrganisationUserAttributes {
  visible_dashboard_views: string[];
}

const fetchOrganisationUserAttributes = async (
  organisationId: number
): Promise<Response<OrganisationUserAttributes>> => {
  return await makeQueryRequest(`${HOST}/v1/organisations/${organisationId}/user_attributes`);
};

const updateOrganisationUserAttributes = async (
  organisationId: number,
  data: OrganisationUserAttributes
): Promise<Response<OrganisationUserAttributes>> => {
  return await makeQueryRequest(`${HOST}/v1/organisations/${organisationId}/user_attributes`, {
    method: HttpMethod.PUT,
    body: data,
  });
};

const queryKeys = {
  useOrganisationUserAttributes: (organisationId: number) => ["organisation_user_attributes", organisationId],
};

const organisations = {
  queryKeys,
  list: () => makeRequest(`${HOST}/v1/organisations`),
  create: (data: Omit<OrganisationType, "id">) =>
    makeRequest(`${HOST}/v1/organisations`, { method: "POST", body: data }),
  get: (data: Pick<OrganisationType, "id">) => makeRequest(`${HOST}/v1/organisations/${data.id}`),
  update: (data: OrganisationType) => makeRequest(`${HOST}/v1/organisations/${data.id}`, { method: "PUT", body: data }),
  leave: (data: Pick<OrganisationType, "id">) => makeRequest(`${HOST}/v1/organisations/${data.id}/leave`),
  transferOwnership: (data: { organisationId: number; data: { id: number } }) =>
    makeRequest(`${HOST}/v1/organisations/${data.organisationId}/transfer`, { method: "PUT", body: data.data }),

  // Reacy Query hooks
  useOrganisationUserAttributes: (organisationId: number | undefined) =>
    useQuery({
      queryKey: queryKeys.useOrganisationUserAttributes(organisationId!),
      queryFn: () => fetchOrganisationUserAttributes(organisationId!),
      select: (data) => data.data,
      enabled: !!organisationId,
    }),
  useUpdateOrganisationUserAttributes: (onSuccess: (data: OrganisationUserAttributes) => void) =>
    useMutation({
      mutationFn: ({ organisationId, data }: { organisationId: number; data: OrganisationUserAttributes }) =>
        updateOrganisationUserAttributes(organisationId, data),
      onSuccess: (data) => {
        onSuccess(data.data);
      },
      retry: 2,
    }),
};

export default organisations;
